<template>
  <div>
    <v-row>
      <v-col>
        <v-label class="pl-2 pt-2 text-subtitle-2" @click="resetCurrent">Organizations
          <v-icon class="ml-2" icon="$reload" @click.stop="refresh" v-if="!form.show" title="Refresh" aria-label="Refresh"></v-icon>
        </v-label>
        <v-label class="pl-2 pt-2 text-subtitle-2" v-if="form.show">/</v-label>
        <v-label class="pl-2 pt-2 text-subtitle-2" v-if="current[id]!==undefined && form.show">{{form.name}}</v-label>
        <v-label class="pl-2 pt-2 text-subtitle-2" v-if="current[id]===undefined && form.show">New Organization</v-label>
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>
    <v-container v-if="form.show">
      <v-form v-model="form.valid" ref="formV" validate-on="blur" autocomplete="off" name="lastpass-disable-search">
          <v-row dense="" class="mt-0">
            <v-col>
              <v-text-field density="compact" id="org_name" label="Organization Name" v-model="current.name" autocomplete="off" :rules="[required]" hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense="">
            <v-divider></v-divider>
            <v-col><v-text-field density="compact" id="org_code" label="Code" autocomplete="off" v-model="current.code" hide-details="auto"></v-text-field></v-col>
            <v-col><v-text-field density="compact" id="org_shortname" label="Short Name" autocomplete="off" v-model="current.shortName" hide-details="auto"></v-text-field></v-col>
          </v-row>
          <v-row dense="">
            <v-col><v-text-field density="compact" id="org_custom_url" label="Email Domain" autocomplete="off" v-model="current.custom_url" hide-details="auto"></v-text-field></v-col>
            <v-col><v-text-field density="compact" id="org_login_domain" label="Login Domain" autocomplete="off" v-model="current.login_domain" :rules="[required]" hide-details="auto"></v-text-field></v-col>
            <v-col><v-text-field density="compact" id="org_website" label="Website" autocomplete="off" v-model="current.website" :rules="[required]" hide-details="auto"></v-text-field></v-col>
          </v-row>
        <v-row dense="">
          <v-col>
            <v-autocomplete :items="countries" item-title="name" @update:modelValue="countrySelected" item-value="countryCode" autocomplete="off" density="compact" id="org_countryCode" label="Country" v-model="current.countryCode" hide-details="auto">
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete :items="timeZones" item-title="value" :item-value="getTimezone" density="compact" id="org_timeZone" label="Timezone" autocomplete="off" v-model="current.timeZone" hide-details="auto">
            </v-autocomplete >
          </v-col>
          <v-col>
            <v-autocomplete  :items="currencies" item-title="name" item-value="code" density="compact" id="org_currency" label="Currency" autocomplete="off" v-model="current.currencyCode" hide-details="auto">
            </v-autocomplete >
          </v-col>
          <v-col>
            <v-autocomplete  :items="languages" item-title="name" item-value="code" density="compact" id="org_language" label="Language" autocomplete="off" v-model="current.languageCode" hide-details="auto">
            </v-autocomplete>
          </v-col>
        </v-row>
          <v-row dense="">
            <v-divider></v-divider>
            <v-col>
              <v-text-field density="compact" id="org_address1" label="Address 1" autocomplete="off" v-model="current.address1" :rules="[required]" hide-details="auto"></v-text-field>
              <v-text-field density="compact" id="org_address2" label="Address 2" autocomplete="off" v-model="current.address2" hide-details="auto"></v-text-field>
              <v-text-field density="compact" id="org_address3" label="Address 3" autocomplete="off" v-model="current.address3" hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense="" class="mt-0">
            <v-col><v-text-field density="compact" id="org_city" label="City" autocomplete="off" v-model="current.city" :rules="[required]" hide-details="auto"></v-text-field></v-col>
            <v-col v-if="1===0"><v-text-field density="compact" id="org_state_code" label="State" autocomplete="off" v-model="current.stateCode" :rules="[required]" hide-details="auto"></v-text-field></v-col>
            <v-col v-if="states.length>0">
              <v-select :items="states" item-title="name" item-value="stateCode" density="compact" id="org_state_code" label="State" autocomplete="off" v-model="current.stateCode" hide-details="auto">
              </v-select>
            </v-col>
            <v-col><v-text-field density="compact" id="org_postal_code" label="Postal Code" autocomplete="off" v-model="current.postalCode" :rules="[required]" hide-details="auto"></v-text-field></v-col>
          </v-row>
          <v-row dense="">
            <v-divider></v-divider>
            <v-col class="mt-3">
              <v-radio-group v-model="current.active" class="mt-3">
                <template v-slot:label>
                  <div>Enabled For Login</div>
                </template>
                <v-radio value="Y">
                  <template v-slot:label>
                    <div>Yes</div>
                  </template>
                </v-radio>
                <v-radio value="N">
                  <template v-slot:label>
                    <div>No</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="1" class="text-center">
              <div><v-icon class="mt-4" style="color: #888888" icon="$map" id="set-coordinates" aria-label="set coordinates" title="set coordinates" @click="onSetLocation"></v-icon></div>
              <div v-if="current.bounds && current.bounds.length>1"><v-icon class="mt-6" style="color: #888888" icon="$mapClear" id="set-coordinates" aria-label="clear coordinates" title="clear coordinates" @click="onClearLocation"></v-icon></div>
            </v-col>
            <v-col>
              <v-row dense="" class="mt-0">
                <v-col><v-text-field density="compact" id="org_lat" label="Latitude" autocomplete="off" v-model="current.y" hide-details="auto"></v-text-field></v-col>
                <v-col><v-text-field density="compact" id="org_lon" label="Longitude" autocomplete="off" v-model="current.x" hide-details="auto"></v-text-field></v-col>
              </v-row>
              <v-row v-if="current.bounds && current.bounds.length>1" style="border: solid 1px #eeeeee; margin: .3rem 0rem 0rem;" >
                <v-col class="d-inline-block text-black" style="background-color: #eeeeee; padding:0 0 0 .4rem; height:1.5rem">{{[current.bounds[0][0].toFixed(4),current.bounds[0][1].toFixed(4)]}}</v-col>
                <v-col class="text-right d-inline-block text-black" style="margin-left:2rem;margin-top: 1.5rem;background-color: #eeeeee; padding:0 0 0 .4rem;">{{[current.bounds[1][0].toFixed(3),current.bounds[1][1].toFixed(3)]}}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="v-col-12 v-col-md-4">
              <v-btn prepend-icon="$add" color="indigo" @click="onAdd" v-if="current[id]===undefined" :disabled="!form.valid || form.sending" class="w-100">Add Organization</v-btn>
              <v-btn prepend-icon="$save" color="indigo" @click="onUpdate" v-if="current[id]!==undefined" :disabled="!form.valid || form.sending" class="w-100">Save Changes</v-btn>
            </v-col>
            <v-col class="v-col-12 v-col-md-4">
              <v-btn @click="resetCurrent"  class="w-100">Cancel</v-btn>
            </v-col>
            <v-col class="v-col-12 v-col-md-4">
              <v-btn prepend-icon="$delete" color="error" @click="onConfirm" v-if="current[id]!==undefined" :disabled="form.sending" class="w-100">Delete</v-btn>
            </v-col>
          </v-row>
       </v-form>
    </v-container>
    <v-container v-if="!form.show">
      <v-row :class="selectedColor(item,'selectable')" v-for="item in list">
        <v-col @click="onSelect(item)" class="pl-2">{{item.name}}</v-col>
        <v-col cols="1"><v-icon :icon="'$right'" @click="onSelect(item)" :title="'Select '+item.name" :aria-label="'Select '+item.name"></v-icon></v-col>
      </v-row>
      <v-btn prepend-icon="$add" color="indigo" @click="onNew" class="w-100 mt-16">New Organization</v-btn>
    </v-container>
    <v-dialog
      v-model="form.confirm"
      width="auto"
    >
      <v-card>
        <v-card-text>
          Are you sure you wish to delete the {{current.name}} organiztion?
        </v-card-text>
        <v-card-actions>
          <v-btn color="warning" @click="onDelete">Yes</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" @click="form.confirm = false" :disabled="form.sending">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="form.map" width="auto">
      <map-select @close="form.map=false" @save="onSaveLocation" :return-bounds="true" :use-org-bounds="false" :use-org-city="false"></map-select>
    </v-dialog>
  </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch, nextTick} from 'vue'
import u from '@/lib/util';
import {v4 as uuid4} from "uuid";
import { db } from '@/lib/db';
import event from '@/lib/event';
import MapSelect from "@/map/mapSelect";
import timeZones from "timezones.json"

async function getCountries(){
  return import('@/locale/countries.json');
}
async function getCurrencies(){
  return import('@/locale/currencies.json');
}

async function getLanguages(){
  return import('@/locale/languages.json');
}

const module = "org"
const id = "oid";
const list = reactive([{
  name: "Loading.."
}]);
u.sortList(timeZones,'value');

const current = reactive({name:'',domain:'',active:'Y'});
const form = reactive({valid:false,show:false,confirm:false,map:false,sending:false,name:"",w:1024,h:768});
const formV = ref(null);

const countries = reactive([]);
const states = reactive([]);
const currencies = reactive([]);
const languages =  reactive([]);

let initialCurrencyCode = '';
let initialLanguageCode = '';

function selectedColor(item,base) {
  return base + (current[id]!=undefined && (current[id]===item[id] ? " bg-grey-darken-2" : ""));
}

function required (v) {
  return !!v || 'Field is required'
}

function resetCurrent() {
  u.clean(current);
  if(formV && formV.reset) formV.reset();
  form.show=false;
  form.confirm=false;
  form.sending=false;
  validate();
}

function countrySelected(code) {
  let country = u.find(countries, { 'countryCode': code });
  if(country.hasStates!=='Y') u.clean(states);
  else u.cleanExtend(states,country.states);

  if(!current.currencyCode || !initialCurrencyCode) {
    current.currencyCode=country.currency[0];
  }

  if(!current.languageCode || !initialLanguageCode) {
    current.languageCode=country.languages[0];
  }
}

function onSelect(item) {
  u.cleanExtend(current,item);
  initialCurrencyCode = current.currencyCode;
  countrySelected(current.countryCode);

  console.info('selected '+item[id],item);
  form.name=current.name;
  form.show=true;
}

function onDelete() {
  form.sending=true;
  event.emit('sync:delete',{module,key:current[id],keyName:id,oid:current.oid});
  resetCurrent();
}

function onNew() {
  resetCurrent();
  form.show=true;
}

function onConfirm() {
  form.confirm=true;
}

function onAdd() {
  current.id = uuid4();
  current.oid = current.id
  if (current.name=="") return;
  form.sending=true;
  let payload = u.cleanExtend({},current);
  event.emit('sync:add',{module,payload,key:current[id],keyName:id,oid:current.oid});
    resetCurrent();
}

function onUpdate(reset) {
  if (current.name=="") return;
  form.sending=true;
  let payload = u.cleanExtend({},current);
  event.emit('sync:update',{module,payload,key:current[id],keyName:id,oid:current.oid});
  if(reset==undefined || reset===true) resetCurrent();
}

function getTimezone(item) {
  return item.utc[0];
}

function onSetLocation() {
  form.map=true;
}

function onClearLocation() {
  current.x=null;
  current.y=null;
  current.bounds=null;
  onUpdate(false);
}

function onSaveLocation(e) {
  u.merge(current,e);
  onUpdate(false);
  form.map=false;
}

function refresh() {
  list.length=0;
  list.push({
    name: "Loading..."
  });
  event.emit('loading',module);
  let oid=localStorage.getItem("oid");
  event.emit('sync',{oid,module});
}

async function refreshed() {
  await db.reload(list,module,'name')
}

function validate() {
  nextTick(()=>{
    if(formV._value && formV._value.validate) formV._value.validate();
    console.debug(formV)
  });
}

onMounted(async ()=>{
  watch(formV, validate, { immediate: true })
  event.on('refreshed', "panel", refreshed);
  refresh();
  await refreshed(module);
  resetCurrent();

  let list=await getCountries();
  u.cleanExtend(countries,list.default);
  list=await getCurrencies();
  u.cleanExtend(currencies,list.default);
  list=await getLanguages();
  u.cleanExtend(languages,list.default);
});
</script>

<style lang="scss" scoped>
</style>
