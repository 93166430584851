<template>
  <div>
    <v-row>
      <v-col>
        <v-label class="pl-2 pt-2 text-subtitle-2" @click="resetCurrent">Holidays
          <v-icon class="ml-2" icon="$reload" @click.stop="refresh" v-if="!form.show" title="Refresh" aria-label="Refresh"></v-icon>
        </v-label>
        <v-label class="pl-2 pt-2 text-subtitle-2" v-if="form.show">/</v-label>
        <v-label class="pl-2 pt-2 text-subtitle-2" v-if="current[id]!==undefined && form.show">{{form.name}}</v-label>
        <v-label class="pl-2 pt-2 text-subtitle-2" v-if="current[id]===undefined && form.show">New Holiday</v-label>
      </v-col>
          </v-row>
    <v-divider class="mt-2"></v-divider>
    <v-container v-if="form.show">
      <v-form v-model="form.valid" ref="$form">
        <v-row>
          <v-col><v-text-field density="compact" label="Name" v-model="current.name" :rules="[required]"></v-text-field></v-col>
          <v-col cols="5"><v-text-field type="date" density="compact" label="Day" v-model="current.date" :rules="[required]"></v-text-field></v-col>
        </v-row>
        <v-row>
        <v-col class="v-col-12 v-col-md-4">
          <v-btn prepend-icon="$add" color="indigo" @click="onAdd" v-if="current[id]===undefined" :disabled="!form.valid || form.sending" class="w-100">Add Holiday</v-btn>
          <v-btn prepend-icon="$save" color="indigo" @click="onUpdate" v-if="current[id]!==undefined" :disabled="!form.valid || form.sending" class="w-100">Save Changes</v-btn>
        </v-col>
        <v-col class="v-col-12 v-col-md-4">
          <v-btn @click="resetCurrent"  class="w-100">Cancel</v-btn>
        </v-col>
        <v-col class="v-col-12 v-col-md-4">
          <v-btn prepend-icon="$delete" color="error" @click="onConfirm" v-if="current[id]!==undefined" :disabled="form.sending" class="w-100">Delete</v-btn>
            </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container v-if="!form.show">
      <v-row :class="selectedColor(item,'selectable')" v-for="item in list">
        <v-col @click="onSelect(item)" class="pl-2">{{item.name}}</v-col>
        <v-col @click="onSelect(item)">{{item.date}}</v-col>
        <v-col cols="1"><v-icon :icon="'$right'" @click="onSelect(item)" :title="'Select '+item.name" :aria-label="'Select '+item.name"></v-icon></v-col>
    </v-row>
      <v-btn prepend-icon="$add" color="indigo" @click="onNew" class="w-100 mt-16">New Holiday</v-btn>
    </v-container>
    <v-dialog
      v-model="form.confirm"
      width="auto"
    >
      <v-card>
        <v-card-text>
          Are you sure you wish to delete this holiday?
        </v-card-text>
        <v-card-actions>
          <v-btn color="warning" @click="onDelete">Yes</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" @click="form.confirm = false" :disabled="form.sending">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import u from '@/lib/util';
import {v4 as uuid4} from "uuid";
import { db } from '@/lib/db';
import event from '@/lib/event';

const module = "holiday"
const id = "id";
const list = reactive([{
  name: "Loading.."
}]);

const current = reactive({name:''});
const form = reactive({valid:false,show:false,confirm:false,sending:false,name:""});
const $form = ref(null);

function selectedColor(item,base) {
  return base + (current[id]!=undefined && (current[id]===item[id] ? " bg-grey-darken-2" : ""));
}

function required (v) {
  return !!v || 'Field is required'
}

function resetCurrent() {
  u.clean(current);
  if($form && $form.reset) $form.reset();
  form.show=false;
  form.confirm=false;
  form.sending=false;
  current.oid=localStorage.getItem("oid");
}

function onSelect(item) {
  u.cleanExtend(current,item);
  console.info('selected '+item[id]);
  form.name=current.name;
  form.show=true;
}

function onDelete() {
  form.sending=true;
  event.emit('sync:delete',{module,key:current[id],keyName:id,oid:current.oid});
  resetCurrent();
}

function onNew() {
  resetCurrent();
  form.show=true;
}

function onConfirm() {
  form.confirm=true;
}

function onAdd() {
  current[id] = uuid4();
  if (current.name=="") return;
  form.sending=true;
  let payload = u.cleanExtend({},current);
  event.emit('sync:add',{module,payload,key:current[id],keyName:id,oid:current.oid});
  resetCurrent();
}

function onUpdate(reset) {
  if (current.name=="") return;
  form.sending=true;
  let payload = u.cleanExtend({},current);
  event.emit('sync:update',{module,payload,key:current[id],keyName:id,oid:current.oid});
  if(reset==undefined || reset===true) resetCurrent();
}

function refresh() {
  list.length=0;
  list.push({
    name: "Loading..."
  });
  event.emit('loading',module);
  let oid=localStorage.getItem("oid");
  event.emit('sync',{oid,module});
}

async function refreshed() {
  await db.reload(list,module,'name');
}

onMounted(async ()=>{
  event.on('refreshed', "panel", refreshed);
  refresh();
  await refreshed(module);
  resetCurrent();
});

</script>

<style lang="scss" scoped>
</style>
