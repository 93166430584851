<template>
  <v-app :theme="props.theme">
    <v-layout>
      <v-app-bar
        permanent
        density="compact"
        class="w-100"
      >
        <v-app-bar-nav-icon icon="$menu" @click.stop="values.drawer = !values.drawer" title="Menu" aria-label="menu"></v-app-bar-nav-icon>
        <v-select class="v-col v-col-sm-3"
                  :items="orgs"
                  id="top-org-select"
                  name="org"
                  item-title="name"
                  item-value="oid"
                  v-model="values.oid"
                  @update:modelValue="onOrgChange"
                  hide-details
                  :single-line="true"
                  aria-label="Organization"
                  label="Organization"
                  v-if="orgs.length>1"
        ></v-select>
        <v-app-bar-nav-icon icon="$add" title="Create" @click="onSectionChange('workCreate')" aria-label="menu"></v-app-bar-nav-icon>
        <v-app-bar-nav-icon icon="$map" title="Create" @click="onMap" aria-label="menu"></v-app-bar-nav-icon>
        <v-app-bar-title></v-app-bar-title>
        <v-app-bar-nav-icon :icon="themeIcon()" title="Change Theme" aria-label="Change Theme" @click="onChangeTheme" class="d-none d-sm-inline-block ml-12"></v-app-bar-nav-icon>
        <v-app-bar-nav-icon icon="$logout" title="Logout" aria-label="Logout" @click="onLogout" class="d-none d-sm-inline-block ml-12"></v-app-bar-nav-icon>
      </v-app-bar>

      <v-main style="width:100%">
        <v-navigation-drawer
        v-model="values.drawer"
        temporary
        >
        <v-list
          :lines="false"
          density="compact"
          role="list"
          nav
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :value="item"
            active-color="primary"
            :active="item.value===values.module"
            role="listitem"
            @click="onSectionChange(item.value)"
          >
            <v-divider v-if="item.type==='divider'"></v-divider>
            <v-list-item-title v-text="item.title" v-if="item.type!=='divider'"></v-list-item-title>
          </v-list-item>
        </v-list>

        </v-navigation-drawer>
        <slot></slot>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script setup>
import { reactive, onMounted, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { db } from '@/lib/db';
import axios from "axios";
import u from '../lib/util.js';
import ev from '@/lib/event';

const router = useRouter();
const route = useRoute();

axios.defaults.withCredentials = true;

const endpoint = import.meta.env.VITE_TARGET;
const B2C_LOGOUT_URL = import.meta.env.VITE_B2C_LOGOUT_URL;

const props=defineProps({
    theme:String,
    pid:String, // project id
});
const emit = defineEmits(['theme','oidChange','pidChange','moduleChange']);
const orgs = reactive([]);
const values = reactive({drawer:false,module:"",oid:""});
const items = [
  {value:'org', title:'Organizations',props:{active:true,activeColor:'primary'}},
  {value:'member',title:'Team Members'},
  {value:'holiday', title:'Holidays'},
];

function menuItems() {
  let active = {active:true,activeColor:'primary'};
  for(let i=0;i<items.length;i++) {
    delete items[i].props;
    if(items[i].value===props.module) {
      items[i].props = active;
    }
  }
  return items;
}

function onChangeTheme() {
  emit('theme', props.theme === 'light' ? 'dark' : 'light');
}
function onOrgChange(id) {
  ev.emit('sync');
  ev.emit('oidChange',id);
  localStorage.setItem('oid',id);
  emit('oidChange', id);
}
async function onSectionChange(route) {
  values.drawer = false;
  if (!route) return;
  emit('moduleChange', route);
  await router.push({
    name: route,
    query: {
      ...route.query
    }
  });
}
async function onMap() {
  values.drawer = false;
  if (!route) return;
  emit('moduleChange', "map");
  await router.push({
    name: "map",
    query: {
      ...route.query
    }
  });
}

async function onLogout() {
  let port=window.location.port;
  if(port==="443" || port==="80") port="";
  else port=":"+port;
  let returnUrl=window.location.protocol + "//" + window.location.host+port;
  let urlReplace = {
    redirect:encodeURIComponent(returnUrl+"/")
  };
  location.href=u.macroReplace(B2C_LOGOUT_URL,urlReplace);
}

function iconClass(s) {
  return props.module===s ? "d-none d-sm-inline text-primary":"d-none d-sm-inline";
}
function themeIcon() {
  return props.theme==='dark'?'$light':'$dark';
}

async function setOrg() {
  orgs.length=0;
  await db.org.each((item)=>{
    orgs.push(item);
  });
  console.debug("setOrg",props.oid);
  if((props.oid==='' || props.oid===null || props.pid===undefined) && orgs.length===1) {
    emit('oidChange', orgs[0].oid);
  }
}

async function refreshDropDowns(module) {
  if(module==='all' || module==='org') await setOrg();
}

onMounted(async ()=>{
  ev.on("refreshed","top",refreshDropDowns);
  values.oid=localStorage.getItem('oid');
  await refreshDropDowns("all");
  if(orgs.length===0) {
    axios.get(endpoint+'/org/00000000-0000-0000-0000-000000000000').then(async (response)=>{
      if(response && response.data) {
        await db.org.clear();
        await db.org.bulkAdd(response.data);
        await setOrg();
      }
    });
  }
});

</script>

<style lang="scss">
</style>
